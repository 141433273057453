<template>
  <div>
    <v-card flat class="shadow mt-3">
      <!-- header -->
      <!-- header -->
      <div>
        <!-- filters -->
        <v-card flat class>
          <v-card-actions class="p-0">
            <v-btn small text @click="show = !show">
              <v-icon small left>fi fi-rr-settings-sliders</v-icon>
              <h4>فلاتر</h4>
            </v-btn>
            <!-- <v-spacer></v-spacer>
                        <v-btn small text color="grey">
                            <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                        </v-btn> -->
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show">
              <v-row class="align-center px-3">
                <v-col cols="12" md="3">
                  <v-autocomplete
                    @input="getItems()"
                    :items="$global.state.provinces"
                    v-model="$global.state.provinceId"
                    item-text="name"
                    item-value="id"
                    clearable
                    dense
                    placeholder="المحافظة"
                    no-data-text="لا توجد بيانات"
                    hide-details
                    outlined
                  />
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
          <v-divider :class="show ? 'mt-3' : 'mt-1'" />
        </v-card>
        <v-row align="center" class="pa-3">
          <v-col cols="12" md="2" class="mb-sm-1">
            <v-btn @click="openAddDialog()" color="primary" class="btn">
              <v-icon small class="pl-1">mdi-plus</v-icon>اضافة
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="12" md="4">
            <div class="d-flex justify-space-between">
              <v-text-field
                v-debounce:500ms="getItems"
                v-model="filter.search"
                placeholder="ابحث هنا"
                hide-details
                dense
                class="pl-3"
                outlined
              />
              <v-select
                @change="getItems"
                v-debounce:500ms="getItems"
                :height="42"
                v-model="filter.pageSize"
                :items="pageSizes"
                no-data-text="لا توجد بيانات"
                style="max-width: 85px"
                hide-details
                outlined
                dense
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- ============================================ -->
      <!-- ============================================ -->
      <!-- ============================================ -->
      <!-- ============================================ -->

      <div class="d-flex justify-content-end flex-wrap align-center">
        <v-card
          class="ma-3"
          max-width="344"
          v-for="(item, i) in projects"
          :key="i"
        >
          <v-img
            cover
            :lazy-src="item.imageUrl"
            :src="item.imageUrl"
            height="200px"
          ></v-img>

          <div class="d-flex justify-space-between">
            <v-card-title>
              {{ item.createdAt | formatDate }}
            </v-card-title>

            <div style="min-width: 75px" class="mt-5">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <edit
                      v-on="on"
                      @refresh="
                        getProjects(
                          filter.pageIndex,
                          filter.pageSize,
                          filter.search
                        )
                      "
                      :project="item"
                    ></edit>
                  </v-btn>
                </template>
                <span>تعديل</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="deleteItem(item.id, i)"
                    v-on="on"
                    x-small
                    icon
                    depressed
                  >
                    <v-icon color="error">fi fi-rr-trash</v-icon>
                  </v-btn>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </div>
          </div>

          <v-card-subtitle>
            {{ i + 1 }}
          </v-card-subtitle>

          <v-card-actions>
            <v-btn color="orange lighten-2" text> الوصف </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="show = !show">
              <v-icon>{{
                show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text>
                {{ item.caption }}
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </div>

      <!-- ============================================ -->
      <!-- ============================================ -->
      <!-- ============================================ -->
      <!-- ============================================ -->
      <!-- table -->

      <v-divider></v-divider>
      <!-- <Pagination :items="projects" /> -->
      <div class="d-flex justify-space-between py-3">
        <v-spacer></v-spacer>
        <v-pagination
          v-model="filter.pageIndex"
          :length="length"
          :total-visible="7"
          @input="getProjects(filter.pageIndex, filter.pageSize, filter.search)"
        ></v-pagination>
      </div>
      <Dialog
        @refresh="getProjects(filter.pageIndex, filter.pageSize, filter.search)"
      />
    </v-card>
  </div>
</template>

<script>
import Edit from "./edit.vue";
export default {
  data() {
    return {
      headers: [
        {
          text: " الوصف",
          value: "caption",
        },
        {
          text: " الصورة",
          value: "imageUrl",
        },
        {
          text: "تاريخ الإضافة",
          value: "createdAt",
        },

        {
          text: "الإجراءات",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      show: false,
      id: "",
      caption: "",
      imageUrl: "",
      createdAt: "",
      actions: "",
      projects: [],
      projectObj: {},
      loading: false,
      uploading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      length: 0,
      filter: {
        pageIndex: 1,
        pageSize: 15,
        search: "",
        count: 0,
      },
      pageSizes: [5, 10, 15, 30, 50, 100],
    };
  },

  components: {
    Dialog: () => import("./dialog"),
    Edit,
  },

  created() {
    this.getProjects(
      this.filter.pageIndex,
      this.filter.pageSize,
      this.filter.search
    );

    this.$eventBus.$on(`refresh`, () => {
      this.getProjects(
        this.filter.pageIndex,
        this.filter.pageSize,
        this.filter.search
      );
    });
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
  },

  methods: {
    getItems() {
      this.$global.dispatch(`get${this.$route.meta.endPoint}`);
    },
    async getProjects(pageIndex, pageSize, search) {
      this.loading = true;
      try {
        let query = `Projects?IsDelete=false`;

        if (pageIndex !== undefined && pageIndex !== null) {
          query += `&PageIndex=${pageIndex}`;
        }

        if (pageSize !== undefined && pageSize !== null) {
          query += `&PageSize=${pageSize}`;
        }

        if (search !== undefined && search !== null) {
          query += `&Search=${search}`;
        }

        const res = await this.$http.get(query);
        this.projects = res.data.result.data;
        this.filter.count = res.data.result.count;
        this.length =
          res.data.result.count == undefined
            ? 0
            : Math.ceil(res.data.result.count / this.filter.pageSize);
      } catch (err) {
        this.dispatch("toastification/setToast", {
          message: `${err.data.message}`,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteItem(id, i) {
      this.$genericService.swalAlertConfirm({}).then((result) => {
        if (result.isConfirmed) {
          this.$genericService
            .delete(`${this.$route.meta.endPoint}`, id)
            .then((res) => {
              this.$store.dispatch("toastification/setToast", {
                message: `${res.data.message}`,
                type: "success",
              });

              let index = this.projects.findIndex((x) => x.id == id);
              this.projects.splice(index, 1);
            })
            .catch((err) => {
              this.$store.dispatch("toastification/setToast", {
                message: `${err.data.message}`,
                type: "error",
              });
            });
        }
      });
    },

    openAddDialog() {
      var item = { dialogType: "add" };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
};
</script>
<style></style>
