<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="50%"
      :fullscreen="isfullscreen"
      persistent
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          @click="SetValues(project)"
          color="info"
          >edit</v-icon
        >
      </template>
      <v-card flat class="px-4 pt-4">
        <!-- dialog toolbar -->
        <v-toolbar height="50" flat>
          <h5>
            تعديل
            {{ this.$route.meta.single }}
          </h5>
          <v-spacer />
          <!-- <v-btn small icon fab color="" @click="isfullscreen = !isfullscreen">
                        <v-icon small>{{ isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand" }}</v-icon>
                    </v-btn> -->
          <v-btn
            @click="close()"
            fab
            small
            icon
            color="grey"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="mx-4" />

        <!-- form body -->
        <v-form
          @submit.prevent="submit()"
          ref="formEditProject"
          lazy-validation
          v-model="valid"
        >
          <v-container>
            <v-card flat class="py-4 px-1">
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">
                    الوصف
                    <span class="required">*</span>
                  </h6>
                  <v-text-field
                    v-model="caption"
                    :rules="[$global.state.required()]"
                    required
                    outlined
                    hide-details="auto"
                    filled
                    dense
                    placeholder="الوصف"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">
                    الصورة
                    <span class="required">*</span>
                  </h6>
                  <v-file-input
                    v-model="image"
                    ref="imageProject"
                    @change="InputImage(image)"
                    :rules="[validImage]"
                    :error-messages="errorMessage"
                    required
                    accept="image/*"
                    outlined
                    hide-details="auto"
                    filled
                    dense
                    placeholder="الصورة"
                  ></v-file-input>
                </v-col>
                <v-col>
                  <v-card flat outlined min-height="200">
                    <v-img
                      max-height="400"
                      contain
                      :src="link ? link : OldLink"
                    >
                    </v-img>
                  </v-card>
                </v-col>
                <!-- attachments -->
              </v-row>
              <v-divider class="ml- mt-8" />

              <!-- actions -->
              <div class="mt-4">
                <v-btn
                  type="submit"
                  :loading="loading"
                  color="primary"
                  class="btn"
                  :disabled="!valid"
                >
                  حفظ
                  <v-icon small right>fi fi-rr-disk</v-icon>
                </v-btn>
                <v-btn
                  @click="close()"
                  text
                  class="mr-2 btn"
                  :disabled="loading"
                >
                  إلغاء
                </v-btn>
              </div>
            </v-card>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["project"],
  data: () => ({
    isfullscreen: false,
    valid: true,
    dialog: false,
    validImage: true,
    errorMessage: "",
    image: null,
    link: null,
    OldLink: null,
    id: null,
    loading: false,
    caption: null,
  }),
  computed: {
    imageLink: function () {
      if (this.link) {
        return this.link;
      }
      return this.OldLink;
    },
  },
  created() {
    //this.reset();
  },

  mounted() {},

  methods: {
    SetValues(proj) {
      //this.reset();
      this.OldLink = proj.imageUrl;
      this.id = proj.id;
      this.caption = proj.caption;
    },

    InputImage(image) {
      //   console.log(image);
      if (!image) {
        this.validImage = true;
        this.errorMessage = null;
        this.link = null;
      } else {
        if (image.type && image.type.startsWith("image/")) {
          // If the image parameter exists and has a type starting with 'image/'
          // Convert the image to base64 and log the link
          this.ConvertImageToBase64(image, (base64Link) => {
            // console.log(base64Link);
            this.link = base64Link;
          });
          this.errorMessage = null;
          this.valid = true;
        } else {
          // If the image parameter is not of type 'image', make it empty or null
          // console.log(image ? null : "");
          this.link = null;
          this.image = null;
          this.errorMessage = "File Must be type Of Image";
          this.valid = false;
          if (image) {
            this.$refs.imageProject.reset();
          }
        }
      }
    },
    ConvertImageToBase64(image, callback) {
      const reader = new FileReader();

      reader.onloadend = function () {
        // Callback with the base64 link when the conversion is complete
        callback(reader.result);
      };

      reader.readAsDataURL(image);
    },
    reset() {
      this.caption = "";
      this.link = null;
      this.OldLink = null;
      this.id = null;
      this.loading = false;
      this.errorMessage = "";
      this.valid = true;
      this.validImage = true;
      this.$refs.formEditProject.reset();
    },

    submit() {
      this.$refs.formEditProject.validate();
      if (this.$refs.formEditProject.validate()) {
        this.editItem();
      }
    },

    async editItem() {
      this.loading = true;
      let formData = new FormData();
      if (this.image) {
        formData.append("Image", this.image);
      }
      formData.append("Caption", this.caption);
      await this.$http
        .put(`${this.$route.meta.endPoint}/${this.id}`, formData, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => {
          this.$emit(`refresh`);
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.dispatch("toastification/setToast", {
            message: `${err.data.message}`,
            type: "error",
          });
        })
        .finally(() => (this.loading = false));
    },

    close() {
      try {
        this.dialog = false;
        this.reset();
        this.resetValidation();
      } catch (error) {
        console.log(error);
      }
    },

    resetValidation() {
      this.$refs.formEditProject.resetValidation();
    },
  },
};
</script>
